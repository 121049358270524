import { Categoria } from "./categoria";
export class ItemCotizacion {
    alto: number;
    ancho: number; 
    categoria: Categoria;
    cif: number;
    cif2: number; //Es para el calculo de la franquicia
    comprado: number;
    coordinationFee: number;
    derechosImportacion: number;
    derechosImportacion2: number;
    descripcion: string;
    excedenteKiloVolumetrico: number;
    fechaCompra: Date;
    fechaRecepcion: Date;
    fleteInternacional: number;
    fob: number;
    fob2: number; //Es para el calculo de la franquicia
    porcentajeFranquicia: number;
    freight: number;
    gastosAdminUsa: number;
    gastosGestionUSA: number;
    honorariosArgentina: number;
    id: number; 
    importeEnDeposito: number;
    impuestosAduana: number;
    impuestosInternos: number;
    insurance: number;
    ivaAduana: number;
    insurance2: number;
    ivaLocal: number;
    ivaLocalUSA: number;
    largo: number;
    link: string;
    peso: number;
    recibidoEnDeposito: number;
    shipping: number;
    tarifaProcesamiento: number;
    tasaEstadistica: number;
    tasaEstadistica2: number;
    taxes: number;
    tca: number;
    handlingUSA: number;
	handlingARG: number;

    constructor() {
        this.id = null;
        this.link = null;
        this.descripcion = null;
        this.categoria = null;
        this.peso = null;
        this.alto = 1;
        this.ancho = 1;
        this.largo = 1;
        this.importeEnDeposito = null;
        this.shipping = null;
        this.coordinationFee = 0;
        this.taxes = 0;
        this.gastosGestionUSA = 0;
        this.gastosAdminUsa = 0;
        this.honorariosArgentina = 0;
        this.ivaLocalUSA = 0;
        this.fleteInternacional = 0;
        this.excedenteKiloVolumetrico = 0;
        this.impuestosAduana = 0;
        this.tarifaProcesamiento = 0;
        this.ivaLocal = 0;
        this.fob = 0;
        this.fob2 = 0;
        this.porcentajeFranquicia = 0;
        this.freight = 0;
        this.insurance = 0;
        this.insurance2 = 0;
        this.cif = 0;
        this.cif2 = 0;
        this.derechosImportacion = 0;
        this.tasaEstadistica = 0;
        this.ivaAduana = 0;
        this.impuestosInternos = 0;
        this.tca = 0;
        this.handlingARG = 0;
        this.handlingUSA = 0;
        this.tasaEstadistica2 = 0;
        this.derechosImportacion2 = 0;
    }
}