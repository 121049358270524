import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { Configuracion } from 'src/app/model/configuracion';
import { Cotizacion } from 'src/app/model/cotizacion';
import { Entrega } from 'src/app/model/entrega';
import { ItemCotizacion } from 'src/app/model/item_cotizacion';
import { Provincia } from 'src/app/model/provincia';
import { TipoDeCambio } from 'src/app/model/tipo_cambio';
import { CommonsService } from 'src/app/services/commons.service';
import { MailService } from 'src/app/services/mail.service';
import { PicksService } from 'src/app/services/picks.service';
import * as ReporteUtil from 'src/app/utils/cotizacion/reporteCotizacion';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
})
export class WelcomePage extends CommonsService implements OnInit {

  //Porner las constantes en tabla de configuracion
  TAXES: number;
  GASTOS_GESTION_USA: number;
  GASTOS_ADMIN_USA: number;
  HONORARIOS_ARGENTINA: number;
  IVA_LOCAL: number;
  TARIFA_PROCESAMIENTO: number;
  TARIFA_FLETE: number;
  TARIFA_SEGURO: number;
  COEFICIENTE_IMPUESTOS_INTERNOS: number;
  TASA_NOMINAL: number;
  VALOR_KILO_VOLUMETRICO: number;
  DESCUENTO_GOLD: number;
  DESCUENTO_PREMIUM: number;
  COORDINATION_FEE: number;
  SHIPPING: number;
  HANDLING: number;
  //La resolucion es un importe fijo de 5 dolares sin iva - ahora viene por parametro
  RES_3244: number;

  //Franquicia de USD 400 a distribuir entre el fob de los articulos
  //el IVA se calcula sobre la base imponible original y no así Derechos, Tasa Estadística, 
  //e impuestos internos que van sobre el FOB que prorratea la franquicia.
  FRANQUICIA_2024: number = 400;

  detalleCotizacion: boolean = false;
  datosCorrectos: boolean = true;
  confirmarDatos: boolean = false;
  mostrarListaItems: boolean = false;
  mensajeError: string = '';
  listaCategorias: any;

  mostrarDescuentoGold: boolean = false;
  mostrarDescuentoPremium: boolean = false;

  listaEntregas: Entrega[];
  tipoDeCambio: TipoDeCambio;

  rows: ItemCotizacion[];
  item: ItemCotizacion;
  cotizacion: Cotizacion;

  totalImporteProductos: number = 0;
  totalEnUSA: number = 0;
  totalEnvioImpuestosAduana: number = 0;
  totalPeso: number = 0;
  totalFleteInternacional: number = 0;
  totalShipping: number = 0;
  totalCoordinationFee: number = 0;
  totalTaxes: number = 0;
  totalGastosGestionUSA: number = 0;
  totalGastosAdminUsa: number = 0;
  totalHonorariosArgentina: number = 0;
  totalIvaUSA: number = 0;

  totalExcedentoKiloVolumetrico: number = 0;
  totalImpuestosAduana: number = 0;
  totalTarifaProcesamiento: number = 0;
  totalIvaLocal: number = 0;
  totalTCA: number = 0;
  totalHandlingARG: number = 0;
  totalHandlingUSA: number = 0;
  
  mostrarDetalleUSA: boolean = false;
  mostrarDetalleArg: boolean = false;

  mostrarGestionCompra: boolean = false;
  mostrarGestionEnvio: boolean = true;

  provincia: Provincia = null;

  unidadMedida: String = 'Cm';
  unidadPeso: String = 'Kg';

  listaUnidadMedida: String[] = ['Cm', 'Pulgadas']
  listaUnidadPeso: String[] = ['Kg', 'Libras']

  listaProvincias: Provincia[] = [
    {
      nombre: 'Buenos Aires'
    },
    {
      nombre: 'Ciudad Autónoma de Buenos Aires'
    },
    {
      nombre: 'Catamarca'
    },
    {
      nombre: 'Chaco'
    },
    {
      nombre: 'Chubut'
    },
    {
      nombre: 'Córdoba'
    },
    {
      nombre: 'Corrientes'
    },
    {
      nombre: 'Entre Ríos'
    },
    {
      nombre: 'Formosa'
    },
    {
      nombre: 'Jujuy'
    },
    {
      nombre: 'La Pampa'
    },
    {
      nombre: 'La Rioja'
    },
    {
      nombre: 'Mendoza'
    },
    {
      nombre: 'Misiones'
    },
    {
      nombre: 'Neuquén'
    },
    {
      nombre: 'Río Negro'
    },
    {
      nombre: 'Salta'
    },
    {
      nombre: 'San Juan'
    },
    {
      nombre: 'San Luis'
    },
    {
      nombre: 'Santa Cruz'
    },
    {
      nombre: 'Santa Fe'
    },
    {
      nombre: 'Santiago del Estero'
    },
    {
      nombre: 'Tucumán'
    },
    {
      nombre: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur'
    }
  ]

  constructor(public toastController: ToastController,
    public loadingController: LoadingController,
    public picksService: PicksService,
    private router: Router,
    public mailService: MailService,) {
    super(toastController, loadingController);
    this.cotizacion = new Cotizacion();
    this.item = new ItemCotizacion();

    const response = this.picksService.buscarCategorias().toPromise();
    response.then(data => {
      this.listaCategorias = data.body;
    });

    const responseEntregas = this.picksService.buscarEntregas().toPromise();
    responseEntregas.then(data => {
      this.listaEntregas = data.body;
    });

    const responseTipoCambio = this.picksService.buscarTipoDeCambio().toPromise();
    responseTipoCambio.then(data => {
      this.tipoDeCambio = data.body;
    });

    const responseConfiguracion = this.picksService.buscarConfiguracion().toPromise();
    responseConfiguracion.then(data => {
      let listaconf: Configuracion[] = data.body;

      listaconf.forEach(element => {

        //console.log(element);

        if (element.propiedad == 'TAXES') {
          this.TAXES = element.valor
        }
        else if (element.propiedad == 'GASTOS_GESTION_USA') {
          this.GASTOS_GESTION_USA = element.valor
        }
        else if (element.propiedad == 'GASTOS_ADMIN_USA') {
          this.GASTOS_ADMIN_USA = element.valor
        }
        else if (element.propiedad == 'HONORARIOS_ARGENTINA') {
          this.HONORARIOS_ARGENTINA = element.valor
        }
        else if (element.propiedad == 'IVA_LOCAL') {
          this.IVA_LOCAL = element.valor
        }
        else if (element.propiedad == 'TARIFA_PROCESAMIENTO') {
          this.TARIFA_PROCESAMIENTO = element.valor
        }
        else if (element.propiedad == 'TARIFA_FLETE') {
          this.TARIFA_FLETE = element.valor
        }
        else if (element.propiedad == 'TARIFA_SEGURO') {
          this.TARIFA_SEGURO = element.valor
        }
        else if (element.propiedad == 'COEFICIENTE_IMPUESTOS_INTERNOS') {
          this.COEFICIENTE_IMPUESTOS_INTERNOS = element.valor
        }
        else if (element.propiedad == 'TASA_NOMINAL') {
          this.TASA_NOMINAL = element.valor
        }
        else if (element.propiedad == 'VALOR_KILO_VOLUMETRICO') {
          this.VALOR_KILO_VOLUMETRICO = element.valor
        }
        else if (element.propiedad == 'DESCUENTO_GOLD') {
          this.DESCUENTO_GOLD = element.valor
        }
        else if (element.propiedad == 'DESCUENTO_PREMIUM') {
          this.DESCUENTO_PREMIUM = element.valor
        }
        else if (element.propiedad == 'COORDINATION_FEE') {
          this.COORDINATION_FEE = element.valor
        }
        else if (element.propiedad == 'SHIPPING') {
          this.SHIPPING = element.valor
        }
        else if (element.propiedad == 'HANDLING') {
          this.HANDLING = element.valor
        }
        else if (element.propiedad == 'RES_3244') {
          this.RES_3244 = element.valor
        }
        else if (element.propiedad == 'FRANQUICIA_2024') {
          this.FRANQUICIA_2024 = element.valor
        }
        
      });
    });
  }

  ngOnInit() {
  }

  precotizar() {

    // antes de cotizar no se muestra el detalle. si pasan las validaciones se habilita
    this.detalleCotizacion = false;

    if (!this.cotizacion.localidadEntrega) {
      this.datosCorrectos = false;
      this.mostrarToastError('Debes ingresar la localidad de entrega')
      return;
    }
    this.cotizacion.res3244 = this.RES_3244;

    //this.mostrarLoader('Cotizando tu pedido');
    this.totalImporteProductos = 0;
    this.totalEnUSA = 0;
    this.totalEnvioImpuestosAduana = 0;
    this.totalPeso = 0;
    this.totalFleteInternacional = 0;

    this.totalShipping = 0;
    this.totalCoordinationFee = 0;
    this.totalTaxes = 0;
    this.totalGastosGestionUSA = 0;
    this.totalGastosAdminUsa = 0;
    this.totalHonorariosArgentina = 0;
    this.totalIvaUSA = 0;

    this.totalExcedentoKiloVolumetrico = 0;
    this.totalImpuestosAduana = 0;
    this.totalTarifaProcesamiento = 0;
    this.totalIvaLocal = 0;

    let cantItems: number = this.cotizacion.items.length;
    let i: number = 0;
    try {

      let totalFOBOriginal: number = 0;
      this.cotizacion.items.forEach(item => {
        totalFOBOriginal += item.importeEnDeposito;
      });
      console.log('totalFOBOriginal:', totalFOBOriginal);
      this.cotizacion.items.forEach(item => {
        item.porcentajeFranquicia = Math.round((item.importeEnDeposito / totalFOBOriginal) * 10000000) / 10000000;
        item.fob2 = Math.round((item.importeEnDeposito - (this.FRANQUICIA_2024 * item.porcentajeFranquicia)) * 10000000) / 10000000;
        
        //si el fob2 queda negativo lo dejamos en 0 para que no calcule derechos
        if(item.fob2 < 0) {
          item.fob2 = 0;
        }
      });

      //calculo de precotizacion de cada producto
      this.cotizacion.items.forEach(item => {
        i++;

        //si hay un solo item, el peso minimo es de 1 kg por envio.
        if(cantItems == 1 && item.peso < 1) {
          item.peso = 1;
        }

        this.totalImporteProductos += item.importeEnDeposito;

        //taxes - shipping - coordinationFee
        item.taxes = item.importeEnDeposito * this.TAXES;
        
        item.shipping = this.SHIPPING;

        /*
        se saca la logica del coordination fee y pasa a valor parametrizable
        if (item.shipping > 0) {
          item.coordinationFee = 0
        }
        else {
          if (item.taxes < 12.5) {
            item.coordinationFee = 10;
          }
          else if (item.taxes >= 12.5 && item.taxes < 17.5) {
            item.coordinationFee = 15;
          }
          else if (item.taxes >= 17.5 && item.taxes < 22.5) {
            item.coordinationFee = 20;
          }
          else if (item.taxes >= 22.5 && item.taxes <= 25) {
            item.coordinationFee = 25;
          }
          else {
            item.coordinationFee = 35;
          }
        }
        */
        item.coordinationFee = this.COORDINATION_FEE;

        //202305 - antes era porcentaje en funcion del valor ahora queda valor fijo.
        //item.gastosGestionUSA = item.importeEnDeposito * this.GASTOS_GESTION_USA;
        item.gastosGestionUSA = this.GASTOS_GESTION_USA;
        item.gastosAdminUsa = item.importeEnDeposito * this.GASTOS_ADMIN_USA;
        item.honorariosArgentina = item.importeEnDeposito * this.HONORARIOS_ARGENTINA;
        item.ivaLocalUSA = item.honorariosArgentina * this.IVA_LOCAL;

        this.totalEnUSA = this.totalEnUSA
          + item.importeEnDeposito
          + item.taxes
          + item.shipping
          + item.gastosGestionUSA
          + item.gastosAdminUsa
          + item.honorariosArgentina
          + item.ivaLocalUSA
          + item.handlingUSA;

        // gastos de envio y aduana
        item.fleteInternacional = item.peso * item.categoria.valorPorKilo;
        this.totalPeso = this.totalPeso + item.peso;
        this.totalFleteInternacional = this.totalFleteInternacional + item.fleteInternacional;
        item.excedenteKiloVolumetrico = 0;
        if ((item.alto * item.ancho * item.largo / 5000) > item.peso) {
          item.excedenteKiloVolumetrico = Math.round((((item.alto * item.ancho * item.largo / 5000) - item.peso) * this.VALOR_KILO_VOLUMETRICO) * 100) / 100;
        }

        //tarifa de procesamiento minimo: 
        //1 prod -> usd20
        //2 prod -> usd28

        //console.log("item " + i + " de " + cantItems)
        let tarifaProcesamiento: number = Math.round((item.importeEnDeposito * this.TARIFA_PROCESAMIENTO) * 100) / 100;
        //console.log("tarifaProcesamientoCalc : " + tarifaProcesamiento)
        

        if (cantItems == 1) {
          if (tarifaProcesamiento < 20) {
            item.tarifaProcesamiento = 20;
          }
          else {
            item.tarifaProcesamiento = tarifaProcesamiento;
          }
        }
        else if (cantItems == 2) {
          if (i == 1) {
            if (tarifaProcesamiento < 20) {
              item.tarifaProcesamiento = 20;
            }
            else {
              item.tarifaProcesamiento = tarifaProcesamiento;
            }
          }
          else {
            if (tarifaProcesamiento < 8) {
              item.tarifaProcesamiento = 8;
            }
            else {
              item.tarifaProcesamiento = tarifaProcesamiento;
            }
          }
        }
        else if (cantItems >= 3) {
          if (tarifaProcesamiento < 8) {
            item.tarifaProcesamiento = 8;
          }
          else {
            item.tarifaProcesamiento = tarifaProcesamiento;
          }
        }

        //calculo de impuestos de aduana      
        item.fob = Math.round(item.importeEnDeposito * 100) / 100;
        item.freight = Math.round(item.peso * this.TARIFA_FLETE * 100) / 100;
        item.insurance = Math.round(((item.fob + item.freight) * this.TARIFA_SEGURO) * 100) / 100;
        item.insurance2 = Math.round(((item.fob2 + item.freight) * this.TARIFA_SEGURO) * 100) / 100;
        item.cif = Math.round((item.fob + item.freight + item.insurance) * 100) / 100;
        
        if(item.fob2 > 0) {
          item.cif2 = Math.round((item.fob2 + item.freight + item.insurance2) * 100) / 100;
        }
        else {
          item.cif2 = 0;
        }
        
        item.derechosImportacion = Math.round((item.cif* item.categoria.derechoImportacion) * 100) / 100;
        item.derechosImportacion2 = Math.round((item.cif2 * item.categoria.derechoImportacion) * 100) / 100;
        item.tasaEstadistica = Math.round((item.cif * item.categoria.tasaEstadistica) * 100) / 100;
        item.tasaEstadistica2 = Math.round((item.cif2 * item.categoria.tasaEstadistica) * 100) / 100;
        item.tca = item.peso;

        item.handlingUSA = Math.round((item.peso * this.HANDLING) *100) / 100;
        item.handlingARG = Math.round((item.peso * this.HANDLING) *100) / 100;
        item.ivaAduana = Math.round(((item.cif + item.derechosImportacion + item.tasaEstadistica) * item.categoria.iva) * 100) / 100;

        //Uso el CIF2 para el calculo de impuestos porque es el que incluye la franquicia
        item.impuestosInternos = Math.round(((((item.cif2 + item.derechosImportacion2 + item.tasaEstadistica2) * this.COEFICIENTE_IMPUESTOS_INTERNOS) * this.TASA_NOMINAL) * item.categoria.impuestoInterno) * 100) / 100;

        console.log('item.tarifaProcesamiento : ' + item.tarifaProcesamiento);
        item.ivaLocal = Math.round(((item.tarifaProcesamiento) * this.IVA_LOCAL) * 100) / 100;
        console.log('item.ivaLocal            : ' + item.ivaLocal);
        console.log('item.link                : ' + item.link);
        console.log('item.fob                 : ' + item.fob);
        console.log('item.fob2                : ' + item.fob2);
        console.log('item.porcentajeFranquicia: ' + item.porcentajeFranquicia);
        console.log('this.TARIFA_FLETE        : ' + this.TARIFA_FLETE);
        console.log('item.peso                : ' + item.peso);
        console.log('item.freight             : ' + item.freight);
        console.log('item.insurance           : ' + item.insurance);
        console.log('item.cif                 : ' + item.cif);
        console.log('item.cif2                : ' + item.cif2);
        console.log('categ.derechoImportacion : ' + item.categoria.derechoImportacion);
        console.log('item.derechosImportacion : ' + item.derechosImportacion);
        console.log('item.derechosImportacion2: ' + item.derechosImportacion2);
        console.log('categ.tasaEstadistica    : ' + item.categoria.tasaEstadistica);
        console.log('item.tasaEstadistica     : ' + item.tasaEstadistica);
        console.log('item.tasaEstadistica2    : ' + item.tasaEstadistica2);
        console.log('item.ivaAduana           : ' + item.ivaAduana);
        console.log('categ.impuestoInterno    : ' + item.categoria.impuestoInterno);
        console.log('TASA_NOMINAL             : ' + this.TASA_NOMINAL);
        console.log('COEF_IMPUESTOS_INT       : ' + this.COEFICIENTE_IMPUESTOS_INTERNOS);
        console.log('item.impuestosInternos   : ' + item.impuestosInternos);
        item.impuestosAduana = item.derechosImportacion2 + item.tasaEstadistica2 + item.ivaAduana + item.impuestosInternos;
        console.log('item.impuestosAduana     : ' + item.impuestosAduana);
        console.log('------------------------------------------------------');
        
        
        this.totalEnvioImpuestosAduana = this.totalEnvioImpuestosAduana
          + item.fleteInternacional
          + item.excedenteKiloVolumetrico
          + item.tarifaProcesamiento
          + item.impuestosAduana;

        this.totalShipping += item.shipping;
        this.totalCoordinationFee += item.coordinationFee;

        // el tope total de coordination fee es de 35
        /* se saca el limite del coordination fee
        if (this.totalCoordinationFee > 35) {
          this.totalCoordinationFee = 35;
        }
        */

        this.totalTaxes += item.taxes;
        this.totalGastosGestionUSA += item.gastosGestionUSA;
        this.totalGastosAdminUsa += item.gastosAdminUsa;
        this.totalHonorariosArgentina += item.honorariosArgentina;
        this.totalIvaUSA += item.ivaLocalUSA;

        this.totalExcedentoKiloVolumetrico += item.excedenteKiloVolumetrico;
        this.totalImpuestosAduana += item.impuestosAduana;
        this.totalTarifaProcesamiento += item.tarifaProcesamiento;
        this.totalTCA += item.tca;
        this.totalIvaLocal += item.ivaLocal;

        this.totalHandlingARG += item.handlingARG;
        this.totalHandlingUSA += item.handlingUSA;

      });

      //Calculo TCA + IVA
      this.cotizacion.tca = this.totalTCA;

      //agrego el IVA del TCA
      this.totalIvaLocal +=  Math.round((this.cotizacion.tca  * this.IVA_LOCAL) * 100) / 100;


      //agrego el coordination fee al total en usa luego de topear a maximo de usd35
      this.totalEnUSA += this.totalCoordinationFee

      //verifico que no supero los topes.
      let errorValidacion: boolean = false;
      let mensajeValidacion = '';
      if (this.totalPeso > 50) {
        errorValidacion = true;
        //console.log('Supera peso maximo permitido por aduana');
        mensajeValidacion = mensajeValidacion + 'Los productos solicitados superan el m&aacute;ximo permitido de 50Kg \n';
      }
      /*
      if (this.totalImporteProductos > 3000) {
        errorValidacion = true;
        //console.log('Supera importe  maximo permitido por aduana');
        mensajeValidacion = mensajeValidacion + 'Los productos solicitados superan el m&aacute;ximo permitido de USD 3000 \n';
      }
      */
      if (errorValidacion) {
        this.delay(300);
        this.ocultarLoader();
        this.mostrarToastError(mensajeValidacion);
        return;
      }

      // si paso todas las validaciones 
      this.detalleCotizacion = true;

      this.cotizacion.descuentoGold = 0;
      this.cotizacion.descuentoPremium = 0;

      this.cotizacion.entrega = 0;
      if (this.cotizacion.localidadEntrega.definido) {
        this.cotizacion.entrega = this.cotizacion.localidadEntrega.valor;
      }

      // el iva local incluye la tarifa de procesamiento + la entrega local
      this.totalIvaLocal += this.cotizacion.entrega * this.IVA_LOCAL;
      this.cotizacion.alicuotaIva = this.IVA_LOCAL;

      //console.log('peso: ' + this.totalPeso);
      //console.log('DESCUENTO_GOLD: ' + (this.totalPeso >= 10 && this.totalPeso < 25));
      if (this.totalPeso >= 10 && this.totalPeso < 25) {
        this.cotizacion.descuentoGold = this.totalFleteInternacional * this.DESCUENTO_GOLD;
        this.mostrarDescuentoGold = true;
      }
      //console.log('DESCUENTO_PREMIUM: ' + (this.totalPeso >= 25));
      if (this.totalPeso >= 25) {
        this.cotizacion.descuentoPremium = this.totalFleteInternacional * this.DESCUENTO_PREMIUM;
        this.mostrarDescuentoPremium = true;
      }

      this.cotizacion.iva = this.cotizacion.entrega * this.IVA_LOCAL;
      
      //202305 solo dejamos: Flete internacional - impuestos de aduana - Tarifa de procesamiento 
      //this.totalEnvioImpuestosAduana = this.totalEnvioImpuestosAduana + this.cotizacion.entrega + this.cotizacion.iva + this.cotizacion.descuentoGold + this.cotizacion.descuentoPremium + this.cotizacion.res3244;

      try {
        this.picksService.registrarPrecotizacion(cantItems).toPromise();
      } catch {
        //console.log('Error registrando precotizacion.');
        this.delay(300);
        this.ocultarLoader();
        this.mostrarToastError('Ha ocurrido un error cotizando tu pedido')
      }

    }
    catch {
      this.delay(300);
      this.ocultarLoader();
      this.mostrarToastError('Ha ocurrido un error cotizando tu pedido')
    }

  }

  async confirmarCotizacion() {
    this.confirmarDatos = true;
  }

  async guardarCotizacion() {

    let respuesta: any;
    this.mostrarLoader('Procesando la solicitud...');
    await this.delay(400);

    //verifico que no supero los topes.
    let errorValidacion: boolean = false;
    let mensajeValidacion = '';
    if (!this.cotizacion.cuit || this.cotizacion.cuit < 20000000000 || this.cotizacion.cuit > 33999999999) {
      errorValidacion = true;
      //console.log('Por favor ingrese un cuit v&aacute;lido');
      mensajeValidacion = mensajeValidacion + 'Por favor ingrese un cuit v&aacute;lido \n';
    }

    if (!this.cotizacion.email || !this.isAValidMail(this.cotizacion.email)) {
      errorValidacion = true;
      //console.log('Por favor ingrese un email v&aacute;lido');
      mensajeValidacion = mensajeValidacion + 'Por favor ingrese un email v&aacute;lido \n';
    }

    /*
    if (!this.cotizacion.celular || !this.isAValidCelular(this.cotizacion.celular)) {
      errorValidacion = true;
      //console.log('Por favor ingrese un celular v&aacute;lido. Ej +5491112341234');
      mensajeValidacion = mensajeValidacion + 'Por favor ingrese un celular v&aacute;lido. Ej +5491112341234 \n';
    }
    */

    if (!this.cotizacion.direccion) {
      errorValidacion = true;
      //console.log('Por favor ingrese una direcci&oacuten');
      mensajeValidacion = mensajeValidacion + 'Por favor ingrese una direcci&oacuten \n';
    }

    if (!this.provincia) {
      errorValidacion = true;
      //console.log('Por favor seleccione una Provincia');
      mensajeValidacion = mensajeValidacion + 'Por favor seleccione una Provincia \n';
    }

    if (!this.cotizacion.localidad) {
      errorValidacion = true;
      //console.log('Por favor ingrese una Localidad');
      mensajeValidacion = mensajeValidacion + 'Por favor ingrese una Localidad \n';
    }

    if (!this.cotizacion.codigoPostal) {
      errorValidacion = true;
      //console.log('Por favor ingrese el c&oacute; postal');
      mensajeValidacion = mensajeValidacion + 'Por favor ingrese el c&oacute;digo postal \n';
    }

    if (errorValidacion) {
      this.delay(300);
      this.ocultarLoader();
      this.mostrarToastError(mensajeValidacion);
      return;
    }

    try {
      //seteo el nombre de la provincia para que no viaje como objeto
      this.cotizacion.provincia = this.provincia.nombre;

      this.cotizacion.tipoCambioGastosArg = Math.round(this.tipoDeCambio.importe * 100) / 100;
      this.cotizacion.tipoCambioGastosExterior = Math.round(this.tipoDeCambio.importe * 100) / 100;

      this.cotizacion.gestionCompra = this.mostrarGestionCompra;

      if(this.mostrarGestionCompra) {
        this.cotizacion.handlingARG = this.totalHandlingARG;
        this.cotizacion.handlingUSA = this.totalHandlingUSA;
      }
      else {
        this.cotizacion.handlingUSA = 0;
        this.cotizacion.handlingARG = this.totalHandlingARG + this.totalHandlingUSA;
      }
      
      //let request = JSON.parse(JSON.stringify(this.cotizacion));
      ////console.log("Request: " + request);
      respuesta = await this.picksService.guardarCotizacion(this.cotizacion).toPromise();
      //console.log("Respuesta: " + respuesta.body);

    } catch (e) {
      //console.log('Ocurrio un error al tratar de guardar la cotizacion');
      //console.log(e);
      this.mostrarToastError('Ocurrio un error al tratar de guardar los datos. Por favor reintente nuevamente.');
      this.ocultarLoader();
      return;
    }

    //OCULTO EL LOADER PORQUE NO MANDAMOS MAILS
    this.ocultarLoader();

    this.mostrarToastOk('Se te ha asignado al comercial ' + respuesta.body.vendedorNombre + ', quien se contactará con vos para validar la cotización');
    let navigationExtras: NavigationExtras;

    if (respuesta) {
      navigationExtras = {
        state: {
          vendedorNombre: respuesta.body.vendedorNombre,
          idCotizacion: respuesta.body.id,
        }
      };
    }

    this.router.navigate(['confirmar'], navigationExtras);

  }

  getDatosCalculados(){

    let valorEntrega = this.cotizacion.localidadEntrega.definido ? 'USD ' + this.cotizacion.entrega.toFixed(2).toString() : 'A definir';
    
    let datos = { 
      //Detalle gastos
      totalImporteProductos : this.totalImporteProductos.toFixed(2).toString() ,
      totalShipping : this.totalShipping > 0? this.totalShipping.toFixed(2).toString(): null ,
      totalCoordinationFee : this.totalCoordinationFee > 0? this.totalCoordinationFee.toFixed(2).toString() : null,
      totalTaxes : this.totalTaxes.toFixed(2).toString(),
      totalGastosGestionUSA : this.totalGastosGestionUSA.toFixed(2).toString(),
      totalGastosAdminUsa : this.totalGastosAdminUsa.toFixed(2).toString(),
      totalHonorariosArgentina : this.totalHonorariosArgentina.toFixed(2).toString(),
      totalIvaUSA : this.totalIvaUSA.toFixed(2).toString(),
      totalEnUSA : this.totalEnUSA.toFixed(2).toString(),
      totalEnUSAConvertido : (this.totalEnUSA * this.tipoDeCambio.importe).toFixed(2).toString(),
      //Detalle envios
      totalFleteInternacional : this.totalFleteInternacional.toFixed(2).toString(),
      totalExcedentoKiloVolumetrico : this.totalExcedentoKiloVolumetrico.toFixed(2).toString(),
      totalImpuestosAduana : this.totalImpuestosAduana.toFixed(2).toString(),
      totalTarifaProcesamiento :  this.totalTarifaProcesamiento.toFixed(2).toString(),
      localidaEntrega : this.cotizacion.localidadEntrega.descripcion,
      valorEntrega :valorEntrega, 
      totalIvaLocal : this.totalIvaLocal.toFixed(2).toString(), 
      mostrarDescuentoGold : this.mostrarDescuentoGold,
      mostrarDescuentoPremium : this.mostrarDescuentoPremium,
      descuentoGold : this.cotizacion.descuentoGold.toFixed(2).toString(),
      descuentoPremium : this.cotizacion.descuentoPremium.toFixed(2).toString(), 
      totalEnvioImpuestosAduana : this.totalEnvioImpuestosAduana.toFixed(2).toString(),
      totalEnvioImpuestosAduanaConvertido: (this.totalEnvioImpuestosAduana * this.tipoDeCambio.importe).toFixed(2).toString(),
      grandTotal : (this.totalEnUSA + this.totalEnvioImpuestosAduana).toFixed(2),
      grandTotalConvertido: ((this.totalEnUSA + this.totalEnvioImpuestosAduana) * this.tipoDeCambio.importe).toFixed(2)
    };
   
    return datos;

  }

  volverACotizar() {
    this.mostrarDescuentoPremium = false;
    this.mostrarDescuentoGold = false;

    this.detalleCotizacion = false;
    this.confirmarDatos = false;
  }


  agregarItem() {

    if (!this.validarItem()) {
      this.mostrarToastError(this.mensajeError);
      return;
    }

    // Transformo a kilos
    if (this.unidadPeso == 'Libras') {
      this.item.peso = this.item.peso / 2.20462;
    }

    // Transformo pulgadas
    if (this.unidadMedida == 'Pulgadas') {
      this.item.alto = this.item.alto * 2.54;
      this.item.ancho = this.item.ancho * 2.54;
      this.item.largo = this.item.largo * 2.54;
    }

    if (!this.cotizacion.items) {
      //console.log('creo la lista');
      this.cotizacion.items = new Array;
    }

    //console.log('this.item.peso: ' + this.item.peso)
    if (this.item.peso < 0.5) {
      this.item.peso = 0.5;
    }
    else {

      const entero = Math.trunc(this.item.peso);
      const decimal = this.item.peso - entero;
      let redondeo = 0;

      if (decimal != 0) {

        if (decimal > 0 && decimal < 0.25) {
          redondeo = 0;
        }
        else if (decimal >= 0.25 && decimal < 0.75) {
          redondeo = 0.5;
        }
        else {
          redondeo = 1;
        }
      }

      //console.log('entero:  ' + entero)
      //console.log('decimal: ' + decimal)

      //const redondeo = Math.round(this.item.peso * 100) / 100;
      this.item.peso = entero + redondeo;

      this.item.handlingUSA = this.item.peso * this.HANDLING;
      this.item.handlingARG = this.item.peso * this.HANDLING;

      //console.log('this.item.peso: ' + this.item.peso);
    }
    this.cotizacion.items.push(this.item);
    this.item = new ItemCotizacion();
    this.rows = [...this.cotizacion.items];
    this.mostrarListaItems = true;

    this.cotizacion.items.forEach(item => {
      item['linkEnPantalla'] = item.link.length > 25 ? item.link.substring(0, 24) : item.link;
    });
    
  }

  validarItem() {
    this.datosCorrectos = true;
    this.mensajeError = '';

    if (!this.isAValidUrl(this.item.link)) {
      this.datosCorrectos = false;
      this.mensajeError = this.mensajeError + 'Debes ingresar el link del producto \n';
    }

    /*
    if (!this.item.descripcion) {
      this.datosCorrectos = false
      this.mensajeError = this.mensajeError + 'Debes ingresar la descripción del producto \n';
    }
    */
    if (!this.item.categoria) {
      this.datosCorrectos = false;
      this.mensajeError = this.mensajeError + 'Debes ingresar la categoría del producto \n';
    }

    if (!this.item.importeEnDeposito) {
      this.datosCorrectos = false;
      this.mensajeError = this.mensajeError + 'Debes ingresar el importe del producto \n';
    }

    /*
    if (this.item.importeEnDeposito > 3000) {
      this.datosCorrectos = false;
      this.mensajeError = this.mensajeError + 'El importe m&aacute;ximo no puede superar los USD 3000 \n';
    }
    */

    if (!this.item.peso) {
      this.datosCorrectos = false;
      this.mensajeError = this.mensajeError + 'Debes ingresar el peso del producto \n';
    }


    if ((this.unidadPeso == 'Libras' && (this.item.peso / 2.20462) > 50) || (this.unidadPeso == 'Kg' && this.item.peso > 50)) {
      this.datosCorrectos = false;
      this.mensajeError = this.mensajeError + 'El peso del producto no puede superar los 50Kg \n';
    }

    if (!this.item.alto || !this.item.ancho || !this.item.largo) {
      this.datosCorrectos = false;
      this.mensajeError = this.mensajeError + 'Debes ingresar las medidas del producto \n';
    }

    return this.datosCorrectos;
  }

  isAValidUrl(link: string): boolean {
    try {
      //console.log(link)
      const url = new URL(link);
      //console.log(url);
      return true;
    } catch (TypeError) {
      //console.log('error' + TypeError);
      return false;
    }
  }

  isAValidMail(email: string): boolean {
    let searchfind: boolean;

    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    searchfind = regexp.test(email);

    //console.log('isAValidMail: ' + searchfind)
    return searchfind
  }

  isAValidCelular(celular: string): boolean {
    let searchfind: boolean;

    let regexp = new RegExp(/^(\+\d{1,3}[- ]?)?\d{11}$/);
    searchfind = regexp.test(celular);

    //console.log('isAValidCelular: ' + searchfind)
    return searchfind
  }

  eliminarItem(rowItem: ItemCotizacion) {

    this.rows = this.rows.filter(obj => obj !== rowItem);
    this.cotizacion.items = this.rows

    if (this.rows.length == 0) {
      this.mostrarListaItems = false;
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  toggleDetalleUsa() {
    this.mostrarDetalleUSA = !this.mostrarDetalleUSA;
  }

  toggleDetalleArgentina() {
    this.mostrarDetalleArg = !this.mostrarDetalleArg;
  }

  toggleGestionCompra() {
    this.mostrarGestionCompra = !this.mostrarGestionCompra;
  }
}

