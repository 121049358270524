import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { Observable } from 'rxjs';
import { Cotizacion } from '../model/cotizacion';
import { TipoPaquete } from '../model/exportacion/tipoPaquete';
import { RateRequest } from '../model/exportacion/rateRequest';

@Injectable({
    providedIn: 'root'
  })
  export class PicksService extends AbstractService {

    constructor(public http: HttpClient) {
      super(http);
    }
  
    buscarCategorias(): Observable<any> {
      return this.getWithObserve(this.EP_CATEGORIAS);
    }

    buscarEntregas(): Observable<any> {
      return this.getWithObserve(this.EP_ENTREGAS);
    }

    buscarTipoDeCambio(): Observable<any> {
      return this.getWithObserve(this.EP_TIPO_DE_CAMBIO);
    }

    guardarCotizacion(cotizacion: Cotizacion): Observable<Cotizacion> {
      this.removeNullValues(cotizacion);
      //console.log('Guardando cotizacion: ' + JSON.stringify(cotizacion));
      return this.postWithObserve(this.EP_COTIZACION, cotizacion);
    }

    buscarConfiguracion(): Observable<any>  {
      return this.getWithObserve(this.EP_CONFIGURACION);
    }

    registrarPrecotizacion(items: number): Observable<any>  {
      return this.postWithObserve(this.EP_REGISTRO + '/' + items, null);
    }

    buscarSeguimiento(idCotizacion: number, cuit: number): Observable<any>  {
      //console.log('buscarSeguimiento: ' + idCotizacion + ' - ' + cuit );
      return this.getWithObserve(this.EP_SEGUIMIENTO + '/' + idCotizacion + '/' + cuit);
    }

    buscarTiposPaquete(): Observable<any> {
      return this.getWithObserve(this.EP_EXPO + '/tipos-paquete');
    }

    buscarPaises(): Observable<any> {
      return this.getWithObserve(this.EP_EXPO + '/paises');
    }

    cotizarExportacionFedex(rateRequest: RateRequest): Observable<any> {
      this.removeNullValues(rateRequest);
      //console.log('Guardando cotizacion: ' + JSON.stringify(cotizacion));
      return this.postWithObserve(this.EP_EXPO + '/cotizacion', rateRequest);
    }
  
}