export const environment = {
  production: true,
  envName: 'prod',

  //Config base.
  serverUrl: 'https://cotizador.waiverpicks.com.ar/waiverlogservices/picks',

  //Enpoints
  categoriasEndpoint: '/categorias',
  entregasEndpoint: '/entregas',
  cotizacionEndpoint: '/cotizacion',
  tipoDeCambioEndpoint: '/tipoCambio',
  configuraciones: '/configuraciones',
  registro: '/registro_precotizacion',
  seguimiento: '/seguimiento',
  envioMail: '/email',

  exportaciones: '/exportaciones'
};
